<template lang="pug">
.z-top.relative
  //- div {{submission.status}}
  q-dialog(v-model="linkedInShare")
    q-card(style="width: 800px; max-width: 90vm")
      q-item
        q-item-section(avatar)
          q-avatar
            img(:src="user.photoURL")
        q-item-section
          q-item-label {{ user.displayName }}
          q-item-label(caption) {{ user.email }}

      q-card-section(v-if="!linkedInLink")
        q-input.q-mx-md.q-mb-md(
          outlined,
          autogrow,
          type="textarea",
          v-model="shareText"
        )
        q-video.q-mx-md.q-mb-md(:src="getYouTube(submission)", :ratio="16 / 9")
      q-card-section(v-else)
        .text-h5.q-mx-md.q-mb-md {{ $t('view_linkedin_post_message') }}

      q-separator
      q-card-actions(align="right")
        q-btn.q-my-sm.q-mr-sm(
          @click="shareToLinkedIn",
          color="primary",
          ripple,
          rounded,
          :loading="postToLinkedIn",
          v-if="!linkedInLink"
        )
          div {{ $t('post_btn') }}

        q-btn.q-my-sm.q-mr-sm(
          @click="openLinkedIn()",
          color="primary",
          ripple,
          rounded,
          v-else
        )
          div {{ $t('view_post_btn') }}

  q-card.overflow-hidden.text-left.shadow-14
    //- q-chip.z-top.absolute-top-right(v-if="config.debug") {{ submission.id }} {{ submission.status }}
    .bg-warning.q-pa-xs.text-white(
      v-if="submission.error"
    ) {{ $t('resubmit_message') }}
    .row
      q-responsive(
        v-if="submission.status == 'edited' || submission.status == 'published'",
        :ratio="16 / 9",
        style="width: 100%; max-width: 100%",
        :class="{ col: $q.screen.gt.sm }"
      )
        q-carousel(
          animated,
          transition-prev="slide-right",
          transition-next="slide-left",
          
          v-model="activeSlide",
          control-type="regular",
          control-color="primary",
          control-text-color="white",
          swipeable,
          infinite
        )
          q-carousel-slide.cursor-pointer(
            key="hd",
            name="hd",
            @click="$emit('showvideo', 'hd_video')",
            :img-src="getThumbnailHd(submission)"
          )
            //- .absolute-top-right
            //-   q-chip.text-white.q-py-md(color="primary", size="md")
            //-     q-icon(:name="matMovie", size="md")
            .absolute-center
              q-icon(:name="matPlayArrow", color="white", size="5em")
          //- q-carousel-slide.cursor-pointer.q-pa-none.bg-grey-9(
          //-   key="social",
          //-   name="social",
          //-   @click="$emit('showvideo', 'square_video')"
          //- )
          //-   q-img.full-height(
          //-     :src="getThumbnailSquare(submission)",
          //-     :ratio="1",
          //-     contain
          //-   )
          //-   .absolute-top-right
          //-     q-chip.text-white.q-py-md(color="primary", size="md")
          //-       q-icon(:name="matShare", size="md")
          //-   .absolute-center
          //-     q-icon(:name="matPlayArrow", color="white", size="5em")

        //- q-img.no-border-radius.cursor-pointer(
        //-   v-if="submission.status == 'edited'",
        //-   @click="$emit('showvideo')",
        //-   :src="getThumbnail(submission)",
        //-   :ratio="16 / 9",
        //-   style="width: 100%; max-width: 100%",
        //-   :class="{ col: $q.screen.gt.sm }"
        //- )

      q-img.no-border-radius.cursor-pointer(
        v-if="submission.status == 'readyformoderation' || submission.status == 'transcribed' && !submission.error",
        :ratio="16 / 9",
        style="width: 100%; max-width: 100%",
        :class="{ col: $q.screen.gt.sm }"
      )
        .absolute-full.text-subtitle2.flex.flex-center
          q-icon(:name="matGavel", size="lg")
          div {{ $t('under_review') }}

      q-img.no-border-radius.cursor-pointer(
        v-if="submission.status == 'moderated'",
        :ratio="16 / 9",
        style="width: 100%; max-width: 100%",
        :src="getThumbnailHd(submission)"
        :class="{ col: $q.screen.gt.sm }"
      )
        .absolute-full.text-subtitle2.flex.flex-center
          q-icon(:name="matHourglassBottom", size="lg")
          div {{ $t('currently_editing') }}

      q-img.no-border-radius.cursor-pointer.bg-grey-2(
        v-if="submission.status == 'draft' || submission.status == 'error_transcode' || submission.status == 'rejected' || submission.status == 'transcribed' || submission.status == 'submitted' || submission.status == 'error' || submission.status == 'transcoded' || submission.status.startsWith('edited_')",
        :ratio="16 / 9",
        style="width: 100%; max-width: 100%",
        :class="{ col: $q.screen.gt.sm }"
        :src="(submission.status!='draft')?getThumbnailHd(submission):''"
      )
        .absolute-full.text-subtitle2.flex.flex-center(
          v-if="submission.status == 'transcoded' || submission.status == 'submitted' || submission.status.startsWith('edited_')"
        )
          q-icon(:name="matSettings", size="lg")
          div {{ $t('processing') }}

        .absolute-full.text-subtitle2.flex.flex-center(
          v-if="submission.status == 'draft' || submission.status == 'error_transcode'"
        )
          q-icon(:name="matInfo", size="lg")
          div {{ $t('incomplete_submission') }}

        .absolute-full.text-subtitle2.flex.flex-center(
          v-if="submission.status == 'rejected'"
        )
          q-icon(:name="matDangerous", size="lg")
          .q-pb-xl.full-height
            .scroll.full-height {{ submission.rejectreason }}

        .absolute-full.text-subtitle2.flex.flex-center(
          v-if="submission.status == 'error' || submission.error"
        )
          q-icon(:name="matWarning", size="lg")
          div {{ $t(submission.error) }}

    q-card-section
      //- .text-h6 {{$t('complete_submission')}}
      div
        q-chip.on-left.q-pl-none(
          rounded,
          v-for="tag of submission.tags",
          :key="tag",
          :icon="getTagIcon(tag)",
          color="transparent",
          text-color="primary"
        )
          q-tooltip {{ $t('tags.' + tag) }}

      //- div(
      //-   v-if="submission.status == 'draft' || submission.status == 'error_transcode'",
      //-   style="height: 36px"
      //- )
      .absolute-top-right.q-ma-md(
        v-if="submission.status == 'draft' || submission.status == 'error_transcode'"
      )
        q-btn(
          :to="'/submission/continue/' + submission.id",
          rounded,
          color="primary",
          unelevated
        ) {{ $t('complete_submission') }}

      //- div(v-if="canShare")
      //-   .absolute-bottom-right.q-ma-sm(v-if="canShare")
      //-     .row
      //-       //- q-btn.custom-linkedin(
      //-       //-   v-if="isLinkedinUser",
      //-       //-   padding="xs",
      //-       //-   unelevated,
      //-       //-   @click="linkedInShare = true"
      //-       //- )
      //-       //-   q-icon(:name="fabLinkedinIn", size="sm")
      //-       AddThis(
      //-         publicId="ra-607e2d6c85125ab7",
      //-         :async="true",
      //-         :data-title="getShareText(submission)",
      //-         :data-url="getUrl(submission)"
      //-       )
  .text-uppercase.text-grey-6.text-weight-bold.text-caption(
    :class="{ 'float-right': dir == 1, 'float-left': dir == 0 }",
    rounded,
    icon="history",
    dense
  ) {{ getTime(submission.createdAt).toRelative() }}
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { openURL } from "quasar";
import find from "lodash/find";
import Language from "@/mixins/Language";
import AddThis from "@/components/AddThis";
import { fabLinkedinIn } from "@quasar/extras/fontawesome-v5";

export default {
  name: "SubmissionItem",
  props: ["submission", "config", "dir"],
  mixins: [Language],
  components: {
    AddThis,
  },
  data: () => {
    return {
      activeSlide: "hd",
      linkedInShare: false,
      postToLinkedIn: false,
      linkedInLink: null,
      shareText: null,
    };
  },
  created() {
    this.fabLinkedinIn = fabLinkedinIn;
  },
  async mounted() {
    this.linkedInLink = this.submission.linkedinPost;
    this.shareText =
      this.getShareText(this.submission) + this.getUrl(this.submission);
    // await this.linkedinUser();
  },
  computed: {
    canShare() {
      return (
        this.submission.status == "edited" ||
        this.submission.status == "finalised" ||
        this.submission.status == "published"
      );
    },
    ...mapState(["user"]),
    ...mapGetters(["getTagIcon"]),
  },
  methods: {
    getUrl(submission) {
      let hd = find(submission.media, (media) => {
        if (media.type === "hd_video" && media.status !== "exporting")
          return true;
      });
      // console.log("https://youtu.be/" + hd.youtube);
      return hd ? "https://youtu.be/" + hd.youtube : "";
    },
    getYouTube(submission) {
      let hd = find(submission.media, (media) => {
        if (media.type === "hd_video" && media.status !== "exporting")
          return true;
      });
      return hd ? `https://www.youtube.com/embed/${hd.youtube}` : "";
    },
    getShareText(submission) {
      let social = find(submission.media, { type: "social" });
      if (social) {
        return social.content.replace(this.getUrl(submission), "");
      } else return "";
    },
    getS3VideoLink() {},
    getS3Link(src) {
      if (!src) return "";
      return `${this.config.cloudfronturl}${src
        .replace("s3://", "")
        .replace(this.config.bucketname, "")}`;
    },
    getThumbnailHd(submission) {
      let raw = find(submission.media, { type: "raw" });
      return `${this.getS3Link(raw.src).replace(".mp4", "")}.0000001.jpg`;
    },
    getThumbnailSquare(submission) {
      let raw = find(submission.media, { type: "square_video_transcoded" });
      if (raw)
        return `${this.getS3Link(raw.src).replace(".mp4", "")}.0000001.jpg`;
      else return this.getThumbnailHd(submission);
    },
    getEditedVideo(submission) {
      return find(submission.media, (media) => {
        if (media.type === "hd_video" && media.status !== "exporting")
          return true;
      });
    },
    async shareToLinkedIn() {
      // const shareText = this.getShareText(this.submission);
      const youtube = this.getUrl(this.submission);
      // const content = shareText + youtube;
      this.postToLinkedIn = true;
      const result = await this.postLinkedIn({
        content: this.shareText,
        youtube,
      });
      console.log("result: ", result);

      if (result.status !== 201) {
        this.linkedInShare = false;
        this.$q.notify({
          type: "negative",
          message: this.$t("post_to_linkedin_warning"),
        });
      } else {
        this.linkedInLink = `https://www.linkedin.com/feed/update/${result.postUrn}`;
        const updateResult = await this.updateSubmission({
          sid: this.submission.id,
          linkedinPost: this.linkedInLink,
        });
        console.log("updateResult: ", updateResult);
      }
      this.postToLinkedIn = false;
    },
    openLinkedIn() {
      openURL(this.linkedInLink);
    },
    ...mapActions(["postLinkedIn", "updateSubmission"]),
  },
};
</script>

<style lang="scss" scoped>
.custom-caption {
  text-align: center;
  padding: 12px;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
}

.custom-linkedin {
  background-color: #0077b5;
  color: white;
  height: 32px;
  border-radius: 0;
  margin-right: 6px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.custom-linkedin:hover {
  transform: translate(0, -5px);
  -webkit-transform: translate(0, -5px);
  -o-transform: translate(0, -5px);
  -moz-transform: translate(0, -5px);
}
</style>
