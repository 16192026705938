<template lang="pug">
q-page.overflow-hidden(style="background:#FFF9ED")
  Countdown.z-max.fixed-bottom(v-on:submit="$router.push('/submission/make')" style="max-width:80vw;margin:auto auto;bottom:10px;" v-if="canSubmit()() && !loading")
  q-dialog(v-model="playVideo")
    q-card(style="width: 800px; max-width: 90vm")
      q-video(
        v-if="currentVideo",
        :src="getYouTube(currentVideo)",
        :ratio="currentVideo.type == 'hd_video' ? 16 / 9 : 1"
      )
      q-btn.full-width(
        :icon-right="matDownload",
        color="grey-5",
        dense,
        flat,
        type="a",
        :href="getNonTubeLink(currentVideo)",
        target="_blank"
      ) {{ $t('no_youtube') }}

  q-dialog(v-model="needsProfile", persistent)
    q-card.full-width
      q-card-section
        .text-h5.q-mb-sm {{ $t('your_name') }}
        q-input(
          autofocus,
          v-model="displayName",
          outlined,
          required,
          :rules="[(val) => !!val || '']"
        )
          template(v-slot:after)
            q-btn.full-width(
              flat,
              :icon="matCheck",
              color="positive",
              @click="displayName.length ? saveDisplayName() : false"
            )

  .row.justify-center.q-mb-lg
    .col-md-8.col-12
      .q-ma-lg.text-left
        .row.justify-center
          div(style="max-width:480px;min-height:260px;")
            .profilebg.q-mt-lg
              //- .cursor-pointer
                //- .text-title.text-center.q-pb-md {{$t('reporter')}}
                
              
              .column.text-item.items-center.text-center.text-white
                .col.text-center
                  .text-h4.text-bold.q-mb-md.q-mt-md Your Details
                .col-auto.text-bold.q-mb-sm Name of organisation
                .col
                  .vicgovfield
                    .col-auto.bold.cursor-pointer {{ user.displayName }}
                    
                      q-popup-edit(
                        @save="displayName.length ? saveDisplayName() : false",
                        v-model="displayName",
                        v-if="ifCanChangeImage()"
                      )
                        q-input(
                          v-model="displayName",
                          autofocus,
                          required,
                          outlined,
                          :rules="[(val) => !!val || '']",
                          :label="$t('your_name')"
                        )
                      q-icon.q-mb-xs.q-ml-sm(
                        :name="matEdit",
                        size="xs",
                        v-if="ifCanChangeImage()"
                      )
                .col-auto.text-bold.q-my-sm Email
                .col
                  .vicgovfield {{ user.email }}
  .vicgoveline &nbsp;
  .row.justify-center.bg-vicgov
    .col-md-11.col-12
      div.q-pt-xl.q-pb-xl.q-mb-xl
        .text-center.text-h4.q-my-xl.text-bold {{ $t('how_to_submit') }}

        .row.text-center.justify-center.text-body1.q-px-md.q-px-md.text-bold
          .col-md-2.col-12.q-px-lg
            q-img(
              style="max-width:250px;"
              src="/submitvideo.svg"
            )
            div.q-mt-lg(:class="{ 'q-mb-md': $q.screen.lt.md }") {{ $t('pipeline0') }}
          .col-md-2.col-12.q-px-lg
            q-img(
              style="max-width:250px;"
              src="/uploadvideo.svg"
            )
            div.q-mt-lg(:class="{ 'q-mb-md': $q.screen.lt.md }") {{ $t('pipeline1') }}
          //- .col-auto.dots(v-if="$q.screen.gt.sm")
            q-icon(:name="matMoreHoriz", size="40px")

          .col-md-2.col-12.q-px-lg
            q-img(
              style="max-width:250px;"
              src="/moderation.svg"
            )
            div.q-mt-lg(:class="{ 'q-mb-md': $q.screen.lt.md }") {{ $t('pipeline2') }}
          //- .col-auto.dots(v-if="$q.screen.gt.sm")
          //-   q-icon(:name="matMoreHoriz", size="40px")

          .col-md-2.col-12.q-px-lg
            q-img(
              style="max-width:250px;"
              src="/productionmagic.svg"
            )
            div.q-mt-lg(:class="{ 'q-mb-md': $q.screen.lt.md }") {{ $t('pipeline3') }}
          //- .col-auto.dots(v-if="$q.screen.gt.sm")
          //-   q-icon(:name="matMoreHoriz", size="40px")

          .col-md-2.col-12.q-px-lg
            q-img(
              style="max-width:250px;"
              src="/emailconfirmation.svg"
            )
            div.q-mt-lg(:class="{ 'q-mb-md': $q.screen.lt.md }") {{ $t('pipeline4') }}
            div.q-mt-lg.text-primary() {{ $t('pipeline5') }}
  .row.justify-center.q-py-xl
    .col-12
      .text-center.text-h4.text-bold.q-mb-xl.q-mt-lg {{ $t('instructions_title') }}
    .row.text-left.justify-center.q-mx-xl.text-body1
      .col-12
        .row
          .col-12
            ol.q-ml-none.q-pl-md.q-my-none.primary-list
              li(v-for="(message, index) in Object.values(messages.instructions)", :key="index") {{ message }}
              //- li(v-for="(message, index) in second", :key="index") {{ message }}
              li {{ $t('instruction_agree') }} <br />
              a(href="/policy", target="_blank") {{ $t('terms_privacy_policy') }}

  .row.justify-center.bg-primary.text-white(style="padding-bottom:180px;")
    .col-md-8.col-12

      .col.q-ma-lg.text-center
        q-spinner(size="xl" v-if="loading")

      .rip1.ripped(v-if="false && canSubmit()() && !loading")
        .text-center
          q-btn(
            unelevated,
            color="secondary",
            no-caps
            text-color="black"
            to="/submission/make",
            :size="$q.screen.gt.sm ? 'lg' : 'lg'"
          )
            //- div {{ $t('submit_to', [$t('phase.phase_' + config.current_phase)]) }}
            div {{$t('submit_now')}}
            //- div(v-if="config.current_phase !== 'submit'") {{ $t('upload_draft') }}
            q-icon(right, :name="matAdd")

        //- .q-ma-lg.text-center.text-body1.q-mb-xl.text-grey-7 {{ $t('submitinfo.phase_' + config.current_phase) }}

      .q-pa-md(v-if="!loading")
        .row.pushdown.q-ml-xs
          q-timeline(color="secondary", :layout="layout", v-if="config")
            q-timeline-entry.relative(
              :title="$t('submissions_opened')",
              :subtitle="getTime(config.opensAt).toRelative()"
            )
              //- template(v-slot:subtitle)
              //-   .ripped.rip1
              //-   .mytitle {{ getTime(config.opensAt).toRelative() }}

            q-timeline-entry.relative(
              v-for="(phase, index) in phases",
              :key="phase.code",
              :icon="getIcon(phase)",
              :title="$t('phase.phase_' + phase.code)",
              :subtitle="getOpenCloseTime(phase).toRelative()",
              :side="index % 2 ? 'right' : 'left'"
            )
              //- template(v-slot:subtitle)
              //-   .ripped(:class="'rip' + (index + 2)")
              //-   .mytitle {{ getOpenCloseTime(phase).toRelative() }}
              //- div {{getSubmissionList(phase.code)}}
              div(v-if="canSubmit()() && phase.code == config.current_phase").q-mb-xl.q-pt-md
                q-btn(
                  unelevated,
                  color="secondary",
                  text-color="black",
                  to="/submission/make",
                  size="lg"
                ) 
                  div {{ $t('submit_now') }}
                  //- div(v-if="config.current_phase !== 'submit'") {{ $t('upload_draft') }}
                  q-icon(right, :name="matAdd")

              SubmissionItem.q-mb-xl(
                :class="{'maxwidth':$q.screen.gt.sm}"
                v-for="submission in getSubmissionList(phase.code)"
                :dir="layout != 'loose' ? 1 : index % 2",
                :key="submission.id"
                v-on:showvideo="showVideo($event, submission)",
                :submission="submission",
                :config="config",
                v-if="getSubmissionList(phase.code).length > 0"
              )

            q-timeline-entry(
              :side="phases.length % 2 ? 'right' : 'left'",
              :title="$t('academy_opens')",
              :subtitle="getTime(config.closesAt).toRelative()"
            )
              //- template(v-slot:subtitle)
              //-   .ripped.rip1
              //-   .mytitle {{ getTime(config.opensAt).toRelative() }}
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import filter from "lodash/filter";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import Language from "@/mixins/Language";
import SubmissionItem from "@/components/SubmissionItem";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import { DateTime } from "luxon";
import Countdown from '../components/Countdown.vue';

export default {
  mixins: [Language],
  name: "SubmissionList",
  components: {
    SubmissionItem,
    LocaleSwitcher,
    Countdown
  },
  async mounted() {
    await Promise.all([this.getSubmissions(), this.initConfig()]);
    this.loading = false;
  },
  data: () => {
    return {
      loading: true,
      playVideo: false,
      currentVideo: null,
      photofile: [],
      displayName: "",
      inlineloading: false,
    };
  },
  watch: {
    loading: {
      immediate: true,
      handler(val) {
        if (val) {
          // this.$q.loading.show({
          //   delay: 400, // ms
          // });
        } else {
          // this.$q.loading.hide();
        }
      },
    },
    inlineloading: {
      handler(val) {
        if (val) {
          this.$q.loading.show({
            delay: 400, // ms
          });
        } else {
          this.$q.loading.hide();
        }
      },
    },
    async photofile() {
      try {
        this.inlineloading = true;
        // console.log(this.photofile);
        if (this.photofile[0]) {
          await this.updateUserPhoto(this.photofile[0]);
        }
      } catch (e) {
        console.log(e);
        this.showError("photo_upload_fail");
      }
      this.inlineloading = false;
    },
  },
  computed: {
    // first() {
    //   return Object.values(this.messages.instructions).splice(
    //     0,
    //     Math.round(Object.values(this.messages.instructions).length / 2)
    //   );
    // },
    // second() {
    //   return Object.values(this.messages.instructions).splice(
    //     Math.round(Object.values(this.messages.instructions).length / 2),
    //     Math.round(Object.values(this.messages.instructions).length / 2)
    //   );
    // },
    messages() {
      return this.$i18n.messages[this.$i18n.locale];
    },
    needsProfile() {
      return this.ifCanChangeImage() && this.user.displayName === "Organisation";
    },
    layout() {
      return this.$q.screen.lt.sm
        ? "dense"
        : // : this.$q.screen.lt.md
          // ? "comfortable"
          "comfortable";
    },
    phases() {
      if (this.config) {
        let avail = filter(this.config.phases, "visible");
        return avail;
      } else {
        return [];
      }
    },
    ...mapState(["config", "submissions", "user"]),
    
  },
  methods: {
    ...mapGetters(["canSubmit"]),
    getIcon(phase) {
      // console.log(phase.icon);
      switch (phase.icon) {
        case "lightbulb":
          return this.matLightbulb;
        case "newspaper":
          return this.matNewspaper;
        case "emoji_events":
          return this.matEmojiEvents;
        case "gavel":
          return this.matGavel;
        default:
          return phase.icon;
      }
    },
    getNonTubeLink(media) {
      if (!media) return "";
      if (!media.src) return "";
      return `${this.config.cloudfronturl}${media.src
        .replace("s3://", "")
        .replace(this.config.bucketname, "")}`;
    },
    async saveDisplayName() {
      try {
        this.inlineloading = true;
        // console.log(this.displayName);
        await this.updateUserDisplayName(this.displayName);
      } catch (e) {
        console.log(e);
        this.showError("photo_upload_fail");
      }
      this.inlineloading = false;
    },
    async updatePhoto() {
      this.$refs.photofile.$el.click();
    },
    ifCanChangeImage() {
      let isemail = find(this.user.providerData, { providerId: "password" });
      return isemail ? true : false;
    },
    getYouTube(media) {
      if (media.youtube)
        return `https://www.youtube.com/embed/${media.youtube}`;
      else return this.getNonTubeLink(media);
    },
    showVideo(kind, submission) {
      // console.log(kind);
      this.playVideo = true;
      this.currentVideo = find(submission.media, (media) => {
        if (media.type === kind && media.status !== "exporting") return true;
      });
    },
    getOpenCloseTime(phase) {
      if (this.getTime(phase.closesAt) < DateTime.now())
        return this.getTime(phase.closesAt);
      else return this.getTime(phase.opensAt);
    },
    isOpen(phase) {
      if (!phase) return false;
      return this.getTime(phase.opensAt) > DateTime.now();
    },
    getSubmissionList(code) {
      // console.log(code);
      // console.log(this.submissions);
      return filter(
        orderBy(this.submissions, ["createdAt._seconds"], ["desc"]),
        {
          phase: code,
        }
      );
    },
    ...mapActions([
      "signOutAction",
      "initConfig",
      "getSubmissions",
      "updateUserDisplayName",
      "updateUserPhoto",
    ]),
  },
};
</script>

<style src="@platyplus/humanitarian-icons/dist/icons.css">
/* global styles */
</style>

<style lang="scss">
@import "@/styles/quasar.variables.scss";


ol.primary-list {
  counter-reset: my-awesome-counter;
  list-style: none;
  padding-left: 40px;
  li {
    counter-increment: my-awesome-counter;
    margin: 0 0 0.5rem 0;
    position: relative;
  }

  li::before {
    content: counter(my-awesome-counter);
    color: $primary;
    font-size: 1.5rem;
    // font-weight: bold;
    position: absolute;
    --size: 24px;
    left: calc(-1 * var(--size) - 10px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 0;
    // background: black;
    // border-radius: 50%;
    text-align: center;
  }
}




.maxwidth {
  max-width: 80%;
}

.badge {
  background: $primary;
  border-top-left-radius: 15px;
  color: white;

  .text-title {
    font-size: 1.5em;
    text-transform: uppercase;
    font-style: italic;
  }

  .text-item {
    border-bottom: dotted 1px $grey-5;
    .col {
      text-transform: uppercase;
      padding-right: 10px;
      font-size: 0.8em;
    }
    .bold {
      text-transform: uppercase;
      font-style: italic;
      font-size: 1em;
    }
  }
}

.ripped {
  // opacity: 0.8;
  min-height: 150px;
  margin-bottom: 70px;
  // position: absolute;
  // right: 0;
  // left: 0;
  padding-top: 80px;
  // transform: translateY(-40%) translateX(-2em) scaleX(120%);
  // -webkit-transform: translate(-2em, -40%) scale(1.2, 1);
  // -o-transform: translate(-2em, -40%) scale(1.2, 1);
  // -moz-transform: translate(-2em, -40%) scale(1.2, 1);
  // z-index: -10;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  // .q-timeline--comfortable & {
  //   min-height: 250px;
  //   position: absolute;
  //   right: 0;
  //   left: 0;
  //   transform: translateY(-40%) translateX(-2em) scaleX(120%);
  //   -webkit-transform: translate(-2em, -40%) scale(1.2, 1);
  //   -o-transform: translate(-2em, -40%) scale(1.2, 1);
  //   -moz-transform: translate(-2em, -40%) scale(1.2, 1);
  //   z-index: -10;
  //   background-repeat: no-repeat;
  //   background-size: 100% 100%;
  // }

  // .q-timeline--dense & {
  //   min-height: 100px;
  //   position: absolute;
  //   right: 0;
  //   left: 0;
  //   transform: translateY(-20%) translateX(-2em) scaleX(120%);
  //   -webkit-transform: translateY(-20%) translateX(-2em) scaleX(120%);
  //   -webkit-transform: translate(-2em, -20%) scale(1.2, 1);
  //   -o-transform: translate(-2em, -20%) scale(1.2, 1);
  //   -moz-transform: translate(-2em, -20%) scale(1.2, 1);
  //   // z-index: -1;
  //   background-repeat: no-repeat;
  //   background-size: 100% 100%;
  // }
}

.mytitle {
  .q-timeline--comfortable & {
    padding-bottom: 230px;
    opacity: 0.4;
  }

  .q-timeline--dense & {
    // padding-top: 0px;
    opacity: 0.4;
  }
}

// .rip1 {
//   background-image: url("~@/assets/img/rip1.jpg");
//   // z-index: -1;
// }

// .rip2 {
//   background-image: url("~@/assets/img/rip2.jpg");
//   // z-index: -2;
// }

// .rip3 {
//   background-image: url("~@/assets/img/rip4.jpg");
//   // z-index: -3;
// }

// .rip4 {
//   background-image: url("~@/assets/img/rip5.jpg");
//   // z-index: -4;
// }

// .rip5 {
//   background-image: url("~@/assets/img/rip6.jpg");
//   // z-index: -5;
// }

.dim {
  opacity: 0.6;
}

// .pushdown {
//   margin-top: 7em;
//   body.screen--xs & {
//     margin-top: 2em !important;
//   }
// }

// .q-timeline__subtitle {
//   opacity: 1 !important;
//   // color: white !important;
// }
</style>

<style lang="scss">
@import "../styles/quasar.variables.scss";

.q-timeline__dot .q-icon {
  /* line-height: 30px; */
  height: 25px !important;
  /* width: 25px !important; */
  top: 7px !important;
  /* left: 5px !important; */
}

/* .photoframe {
  /* background-image: url("~@/assets/img/frame.png"); */
/* background-position: center; */
/* background-size: contain; */
/* background-repeat: no-repeat; */
/* min-height: 240px; */
/* } */

.rounder {
  position: relative;
  background: $primary;
  /* border: 1px solid black; */
  max-width: 75px;
  max-height: 75px;
  color: white;
  border-radius: 50%;
  padding: 15px;
  margin-bottom: 0.5em;
}

.dots {
  margin-top: 15px;
}

.nobullet {
  list-style: none;
}

.profilebg {
  background-image: url('~@/assets/profile.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: center;
  width:480px;
  height:265px;
}

.vicgovfield {
  background: #FFF9ED;
  border-radius: 16px;
  color:black;
  padding:1em;
  font-weight: bold;
  width:300px;
}

.vicgoveline {
  border-radius: 50% 50% 50% 50% / 4% 4% 96% 96%;
  background-color:  #FFF9ED;
  width:100%;
  height:20px;
  // margin-top:20px;
  position: absolute;
  margin-top:-1px;
}

.bg-vicgov{
  background-color: #F8EEDC;
}
</style>
