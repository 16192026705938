import { render, staticRenderFns } from "./SubmissionItem.vue?vue&type=template&id=48013851&scoped=true&lang=pug&"
import script from "./SubmissionItem.vue?vue&type=script&lang=js&"
export * from "./SubmissionItem.vue?vue&type=script&lang=js&"
import style0 from "./SubmissionItem.vue?vue&type=style&index=0&id=48013851&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48013851",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QVideo from 'quasar/src/components/video/QVideo.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QResponsive from 'quasar/src/components/responsive/QResponsive.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QItem,QItemSection,QAvatar,QItemLabel,QCardSection,QInput,QVideo,QSeparator,QCardActions,QBtn,QChip,QResponsive,QCarousel,QCarouselSlide,QIcon,QImg,QTooltip});
